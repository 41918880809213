import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {
    pacienteIncluindoNome: ""
};

const getters = {
    pacienteIncluindoNome(state) {
        return state.pacienteIncluindoNome;
    }
};

const mutations = {
    updatePacienteIncluindoNome(store, pacienteIncluindoNome) {
        store.pacienteIncluindoNome = pacienteIncluindoNome;
    }
};

const actions = {
    atualizarPacienteIncluindoNome(context, pacienteIncluindoNome) {
        context.commit("updatePacienteIncluindoNome", pacienteIncluindoNome);
    },
    getPacientes(context, dados) {
        if ((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados = {
                pagina: 1,
                quantidadePorPagina: 25
            };
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacientesBox(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/listaBox", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacientesCpfBox(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/listaCpfBox", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPaciente(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/obter", { ID_PACIENTE: idPaciente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacienteSimples(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/obterSimples", { ID_PACIENTE: idPaciente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacientesCPF(context, cpf) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/listarCPF", { CPF: cpf }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacientesTelefone(context, telefone) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/listarTelefone", { TELEFONE: telefone }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacienteFoto(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/obterFoto", { ID_PACIENTE: idPaciente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacientesFotos(context, idsPacientes) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/obterFotos", idsPacientes).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    deletePaciente(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/excluir", { ID_PACIENTE: idPaciente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postPaciente(context, paciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/salvar", paciente).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getVerificaExisteRG(context, RG) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/verificaExisteRG", { RG: RG }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacienteAtendimentosQuantidade(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/pacienteAtendimentosQuantidade", { ID_PACIENTE: idPaciente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    updatePaciente(context, paciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/salvar", paciente).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    buscarAprovacoesImagens(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/buscarAprovacoesImagens", {ID_PACIENTE: idPaciente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    excluirAprovacaoImagem(context, idAprovacaoImagem) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/excluirAprovacaoImagem", {ID_PACIENTE_IMAGEM_APROVACAO: idAprovacaoImagem}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    excluirImagem(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/excluirImagem", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    salvarAprovacaoImagem(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/salvarAprovacaoImagem", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    indicarAssociacaoDocumento(context, idDocumento) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/indicarAssociacaoDocumento", {ID_PACIENTE_DOCUMENTO: idDocumento}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    temEspacoArmazenamento() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("pacientesImagens/access/temEspacoArmazenamento").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvarImportador(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientesImagens/access/salvarImportador", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getPacientesOcorrencias(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/listarPacienteOcorrencias", {ID_PACIENTE: idPaciente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAvisosPaciente(context, idPaciente, idConvenio, idAgenda) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("pacientes/access/listarAvisosPaciente", {ID_PACIENTE: idPaciente, ID_CONVENIO: idConvenio, ID_AGENDA: idAgenda}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }

};

export default {
    state,
    actions,
    mutations,
    getters
};